import React from 'react';
import img_aboutUs from './img/home-about-us.jpg';
import img_sales from './img/home-sales.jpg';
import img_contact from './img/home-contact-2.jpg';
import ContactForm from './ContactForm';
import Brands from './Brands';

function Cards() {
  return (
    <>
    <div className="deck">
    <div className="cards-wrapper">
    <div className="card-container">
        <div className="card-hero">
            <div className="card-image-wrapper">
              <img src={img_aboutUs} alt="" />
            </div>
            <div className="card-heading-overlay"></div>
            <h2 className="card-heading-overlay-text">About Us</h2>
        </div>
        <div className="card-text-box">
          <p>Greenland Audio is a well-established company with over 35 years of experience. Greenland Audio is preceded by a reputation that has been earned primarily in the fields of audio-visual production, design & consultation and sales including installation. We are proud of our long-standing relationships with specialized industry knowledge both as a Wholesale & Direct Supplier. </p>
        </div>
    </div>
    </div>
    <div className="cards-wrapper">
    <div className="card-container">
        <div className="card-hero">
            <div className="card-image-wrapper">
              <img src={img_sales} alt="" />
            </div>
            <div className="card-heading-overlay"></div>
            <h2 className="card-heading-overlay-text">Sales</h2>
        </div>
        <div className="card-text-box card-brands">
          <p>Greenland Audio sell and install from a list of industry leading brands. Direct sales are supported by offering best choice and price options.</p>
          <Brands />
        </div>
    </div>
    </div>
    <div className="cards-wrapper">
    <div className="card-container">
        <div className="card-hero">
            <div className="card-image-wrapper">
              <img src={img_contact} alt="" />
            </div>
            <div className="card-heading-overlay"></div>
            <h2 className="card-heading-overlay-text">Contact Us</h2>
        </div>
        <div className="card-text-box">
          <ContactForm />
        </div>
    </div>
    </div>
    </div>
    </>
  )
}

export default Cards
