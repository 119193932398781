import { useRef } from 'react'
import {useForm} from 'react-hook-form'
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";

function ContactForm() {
    // initialize the functions you use from useForm
    const {register, handleSubmit, getValues,} = useForm();
    const reRef=useRef();
    
    //when the submission button is pressed, this triggers the captcha to pop up
    const onSubmitWithReCAPTCHA = () => {
      reRef.current.executeAsync();
    }


    //this code runs when a person completes the captcha.
    const onReCAPTCHAChange = async captchaCode => {
      //this if is a failsafe in case the captcha hasn't been completed.
      if (!captchaCode) {
        return
      }
        //the "reRef" here is talking about the captcha. "getValue" returns the completed key
        const token = reRef.current.getValue();
        //getValues is not to be confused with getValue. getValues is a function from react-hook-form that gets the users input
        var params = getValues();
        params['g-recaptcha-response'] = token;

        emailjs.send("contact_service", "template_xzxyksm", params, {
          publicKey: '8ONWmbov70Pu41rPS',
          limitRate: {
            // Set the limit rate for the application
            id: 'app',
            // Allow 1 request per 10s
            throttle: 10000,
          },
        }).then(
          () => {
            window.alert("Form submitted successfully");
            console.log('SUCCESS!');
          },
          (error) => {
            window.alert("There was an error with the form...", error.text);
            console.log('FAILED...', error.text);
          },
        );
    }

  return (
    <div className="contact-form-wrapper">
      <form className="contact-us-form" onSubmit={handleSubmit((data) => {onSubmitWithReCAPTCHA();})}>
        <input type="text" className="contact-input-personal-info contact-input" {...register("firstName", {required: true,})} required placeholder="First Name" />
        <input type="text" className="contact-input-personal-info contact-input" {...register("lastName")} required placeholder="Last Name" />
        <input type="email" className="contact-input-personal-info contact-input" {...register("emailAddress")} required placeholder="Email Address"/>
        <input type="text" className="contact-input-personal-info contact-input" {...register("phoneNumber")} required placeholder="Phone Number"/>
        <textarea className="contact-input-message contact-input" {...register("message")} required placeholder="Message" />
        <input type="submit" className="contact-input-submit" text="Submit"/>
        <ReCAPTCHA
        sitekey="6Lc-ZN0pAAAAAAwvI31WbuUDVlf_0rJMH6MWac-_"
        // This onChange function is called AFTER the submit triggers the captcha
        onChange={onReCAPTCHAChange}
        size="invisible"
        ref={reRef}
      />
      </form>
    </div>
  )
}

export default ContactForm
