import React from 'react'
import * as i from './ImageImporter';

function Brands() {
  return (
    <div>
      <div class="home-brands-container">
  <img class="home-brand-image" src={i.akg_175} alt="akg logo"/>
  <img class="home-brand-image" src={i.allenheath_175} alt="Allen and Heath logo"/>
  <img class="home-brand-image" src={i.audiotechnika_175} alt="Audio Technika logo"/>
  <img class="home-brand-image" src={i.behringer_175} alt="Behringer Logo"/>
  <img class="home-brand-image" src={i.blackmagic_175} alt="Blackmagic Design Logo"/>
  <img class="home-brand-image" src={i.chauvetdj_175} alt="Chavuet DJ Logo"/>
  <img class="home-brand-image" src={i.chauvetpro_175} alt="Chavuet Pro Logo"/>
  <img class="home-brand-image" src={i.coreswx_175} alt="Core SWX Logo"/>
  <img class="home-brand-image" src={i.klarkteknik_175} alt="Klark Teknik Logo"/>
  <img class="home-brand-image" src={i.martin_175} alt="Martin Logo"/>
  <img class="home-brand-image" src={i.midas_175} alt="Midas Logo"/>
  <img class="home-brand-image" src={i.quest_175} alt="Quest Logo"/>
  <img class="home-brand-image" src={i.radial_175} alt="Radial Logo"/>
  <img class="home-brand-image" src={i.rcf_175} alt="RCF Logo"/>
  <img class="home-brand-image" src={i.rode_175} alt="Rode Logo"/>
  <img class="home-brand-image" src={i.sennheiser_175} alt="Sennheiser Logo"/>
  <img class="home-brand-image" src={i.showpro_175} alt="ShowPro Logo"/>
  <img class="home-brand-image" src={i.shure_175} alt="Shure Logo"/>
</div>
    </div>
  )
}

export default Brands
