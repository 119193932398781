import React from 'react'

const Footer = () => {
  return (
    <>
      <div className="footer-container">&#169; Greenland Audio 2024</div>
    </>
  )
}

export default Footer
