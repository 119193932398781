export const akg_175 = require('./img/brands/175/brand-collage-akg-175.jpg')
export const allenheath_175 = require('./img/brands/175/brand-collage-allenheath-175.jpg')
export const audiotechnika_175 = require('./img/brands/175/brand-collage-audiotechnika-175.jpg')
export const behringer_175 = require('./img/brands/175/brand-collage-behringer-175.jpg')
export const blackmagic_175 = require('./img/brands/175/brand-collage-blackmagic-175.jpg')
export const chauvetdj_175 = require('./img/brands/175/brand-collage-chauvetdj-175.jpg')
export const chauvetpro_175 = require('./img/brands/175/brand-collage-chauvetpro-175.jpg')
export const coreswx_175 = require('./img/brands/175/brand-collage-coreswx-175.jpg')
export const klarkteknik_175 = require('./img/brands/175/brand-collage-klarkteknik-175.jpg')
export const konigmeyer_175 = require('./img/brands/175/brand-collage-konigmeyer-175.jpg')
export const martin_175 = require('./img/brands/175/brand-collage-martin-175.jpg')
export const midas_175 = require('./img/brands/175/brand-collage-midas-175.jpg')
export const quest_175 = require('./img/brands/175/brand-collage-quest-175.jpg')
export const radial_175 = require('./img/brands/175/brand-collage-radial-175.jpg')
export const rcf_175 = require('./img/brands/175/brand-collage-rcf-175.jpg')
export const rode_175 = require('./img/brands/175/brand-collage-rode-175.jpg')
export const sennheiser_175 = require('./img/brands/175/brand-collage-sennheiser-175.jpg')
export const showpro_175 = require('./img/brands/175/brand-collage-showpro-175.jpg')
export const shure_175 = require('./img/brands/175/brand-collage-shure-175.jpg')