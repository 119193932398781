import React from 'react'
import logo from './img/logo.png'
import { useEffect } from 'react'

const Sidebar = () => {
  useEffect(() => {
    let nav = document.querySelector('.header-container')
    let sticky = nav.offsetHeight

    console.log(nav, sticky)
  });

  return (
    <div className="header-container">
      <div className="header-logo-container"><img className="header-logo" src={logo} alt="" /></div>
      <div className="header-phone"><b> <a href="tel:+61394384855">(03) 9438 4855</a></b></div>
    </div>
  )
}

export default Sidebar
