import './App.css';
import './scss/global.scss';
import Sidebar from './components/Sidebar';
import Cards from './components/Cards';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Sidebar />
      <Cards />
      <Footer />
    </div>
  );
}

export default App;
